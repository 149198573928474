export default [
  {
    path: '/leads/leads-list',
    name: 'leads-list',

    component: () => import('@/views/leads/LeadsList.vue'),
    meta: {
      Permission: 'lead_access',
    },
  },

  {
    path: '/leads/junk-leads-list',
    name: 'junk-leads-list',

    component: () => import('@/views/leads/JunkLeadList.vue'),
    meta: {
      Permission: 'lead_access',
    },
  },


  {
    path: '/leads/leads-add',
    name: 'leads-add',
    component: () => import('@/views/leads/LeadsAdd.vue'),
    meta: {
      Permission: 'lead_create',
    },
  },

  {
    path: '/leads/leads-edit/:id',
    name: 'leads-edit',
    component: () => import('@/views/leads/LeadsEdit.vue'),
    meta: {
      Permission: 'lead_edit',
    },
  },

  {
    path: '/leads/leads-preview/:id',
    name: 'leads-preview',
    component: () => import('@/views/leads/LeadsPreview.vue'),
    meta: {
      Permission: 'lead_access',
    },
  },
];
