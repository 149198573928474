import Vue from 'vue';

// axios
import axios from 'axios';
let auth = '';
if (localStorage.getItem('token')) {
  auth = 'Bearer ' + localStorage.getItem('token');
}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://127.0.0.1:8000/api',
  // baseURL: 'https://api.mrorhan.com/public/api',
  // baseURL: 'https://api.regenwald.ca/api',
  // baseURL: 'https://api.mrorhan.com/public/api',

  // baseURL: 'https://api.demo2.portfolio.tmss-repo.com/public/api',
  
  // baseURL: 'https://api-canada-test.azurewebsites.net/api',
  baseURL: 'https://api.regenwald.tmss-repo.com/public/api',


  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
});

/*
axiosIns.defaults.headers.post["Content-Type"] = "*";
axiosIns.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axiosIns.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
*/

Vue.prototype.$http = axiosIns;

export default axiosIns;
