export default [
  {
    path: '/sales/quote-add',
    name: 'sales-add',
    component: () => import('@/views/sales/create-quote/AddQuote.vue'),

    meta: {
      Permission: 'sale_quote_create',
    },
  },
  {
    path: '/sales/quote-list',
    name: 'sales-list',
    component: () => import('@/views/sales/quote-list/QuoteListShow.vue'),

    meta: {
      Permission: 'sale_quote_list',
    },
  },

  {
    path: '/sales/archive-quote-list',
    name: 'archive-sales-list',
    component: () => import('@/views/sales/quote-list/QuoteListArchive.vue'),

    meta: {
      Permission: 'view_sale_archive_records',
    },
  },

  {
    path: '/sales/quote-edit/:id',
    name: 'sales-edit',
    component: () => import('@/views/sales/create-quote/EditQuote.vue'),
    meta: {
      Permission: 'sale_quote_edit',
    },
  },

  {
    path: '/sales/sales-validation-edit/:id',
    name: 'sales-validation-edit',
    component: () => import('@/views/sales/create-quote/EditQuote.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/sales/quote-preview/:id',
    name: 'sales-preview',
    component: () => import('@/views/sales/quote-preview/PreviewQuote.vue'),

    meta: {
      Permission: 'sale_quote_show',
    },
  },

  {
    path: '/sales/archive-quote-preview/:id',
    name: 'archive-sales-preview',
    component: () => import('@/views/sales/quote-preview/PreviewQuote.vue'),

    meta: {
      Permission: 'sale_quote_show',
    },
  },
];
