import { $themeBreakpoints } from "@themeConfig";
import axiosIns from "@/libs/axios";
import router from "@/router";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,

  state: {
    // baseURL: "http://127.0.0.1:8000/storage/images/",
    // profileURL: "http://127.0.0.1:8000/storage/profile/",
    // fileURL: "http://127.0.0.1:8000/storage/files/",
    // settingURL: "http://127.0.0.1:8000/storage/",
    // purchaseOrderURL: "http://127.0.0.1:8000/storage/purchase/",
    // saleOrderURL: "http://127.0.0.1:8000/storage/sale/",
    // settingURL: "http://127.0.0.1:8000/storage/settings/",
    // adjustmentURL: "http://127.0.0.1:8000/storage/adjustment/",

    // baseURL: "https://api.regenwald.ca/storage/images/",
    // profileURL: "https://api.regenwald.ca/storage/profile/",
    // fileURL: "https://api.regenwald.ca/storage/files/",
    // purchaseOrderURL: "https://api.regenwald.ca/storage/purchase/",
    // saleOrderURL: "https://api.regenwald.ca/storage/sale/",
    // settingURL: "https://api.regenwald.ca/storage/settings/",
    // adjustmentURL: "https://api.regenwald.ca/storage/adjustment/",

    // baseURL: "https://api.mrorhan.com/storage/images/",
    // profileURL: "https://api.mrorhan.com/storage/profile/",
    // fileURL: "https://api.mrorhan.com/storage/files/",
    // purchaseOrderURL: "https://api.mrorhan.com/storage/purchase/",
    // saleOrderURL: "https://api.mrorhan.com/storage/sale/",
    // settingURL: "https://api.mrorhan.com/storage/settings/",
    // adjustmentURL: "https://api.mrorhan.com/storage/adjustment/",

    baseURL: "https://api.regenwald.tmss-repo.com/storage/app/public/images/",
    profileURL: "https://api.regenwald.tmss-repo.com/storage/app/public/profile/",
    fileURL: "https://api.regenwald.tmss-repo.com/storage/app/public/files/",
    purchaseOrderURL: "https://api.regenwald.tmss-repo.com/storage/app/public/purchase/",
    saleOrderURL: "https://api.regenwald.tmss-repo.com/storage/app/public/sale/",
    settingURL: "https://api.regenwald.tmss-repo.com/storage/app/public/settings/",
    adjustmentURL: "https://api.regenwald.tmss-repo.com/storage/app/public/adjustment/",
    

    windowWidth: 0,
    shallShowOverlay: false,
    token: "",
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },

    isAuthenticated(state) {
      return state.token !== "";
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },

    /*  SET_TOKEN(state, token) {
          state.token = token;
        },
        CLEAR_TOKEN(state) {
          state.token = "";
        },*/
  },
  actions: {
    /* INIT_AUTH({ commit, dispatch }) {
          let token = localStorage.getItem("token");

          if (token) {
            let expirationDate = localStorage.getItem("expirationDate");
            let nowTime = new Date().getTime();

            if (nowTime >= +expirationDate) {
              store.commit("app/CLEAR_TOKEN");
              localStorage.removeItem("token");
              localStorage.removeItem("expirationDate");

              router.replace("/login");
            } else {
              commit("SET_TOKEN", token);
              let timerSecond = +expirationDate - nowTime;

              store.dispatch("app/setTimeOutTimer", timerSecond);
              //  router.push({ name: "/" });
            }
          } else {
            router.push({ name: "login" });
            return false;
          }
        },*/
    /*  setTimeOutTimer({ dispatch }, expiresIn) {
          setTimeout(() => {
            store.commit("app/CLEAR_TOKEN");
            localStorage.removeItem("token");
            localStorage.removeItem("expirationDate");
            router.push({ name: "login" });
            //  router.push({ path: "login" });
          }, expiresIn);
        },*/
  },
};
