export default [
  {
    path: '/orders/add-expense',
    name: 'add-expense',

    component: () => import('@/views/orders/orders-add/AddPurchaseOrder.vue'),
    meta: {
      Permission: 'order_add',
    },
  },

  {
    path: '/orders/add-income',
    name: 'add-income',

    component: () => import('@/views/orders/orders-add/AddSaleOrder.vue'),
    meta: {
      Permission: 'order_add',
    },
  },

  {
    path: '/orders/add-adjustment',
    name: 'add-adjustment',

    component: () => import('@/views/orders/orders-add/AddAdjustment.vue'),
    meta: {
      Permission: 'order_add',
    },
  },

  {
    path: '/orders/purchase-order',
    name: 'purchase-order',

    component: () => import('@/views/orders/orders-list/PurchaseOrder.vue'),
    meta: {
      Permission: 'order_list',
    },
  },

  {
    path: '/orders/sale-order',
    name: 'sale-order',

    component: () => import('@/views/orders/orders-list/SaleOrder.vue'),
    meta: {
      Permission: 'order_list',
    },
  },

  {
    path: '/orders/vehicle-adjustment',
    name: 'vehicle-adjustment',

    component: () => import('@/views/orders/orders-list/VehicleAdjustment.vue'),
    meta: {
      Permission: 'order_list',
    },
  },

  {
    path: '/orders/expense-preview/:id',
    name: 'expense-preview',

    component: () => import('@/views/orders/orders-preview/PurchaseOrderPreview.vue'),
    meta: {
      Permission: 'order_show',
    },
  },

  {
    path: '/orders/expense-edit/:id',
    name: 'expense-edit',
    component: () => import('@/views/orders/orders-add/EditPurchaseOrder.vue'),
    meta: {
      Permission: 'order_edit',
    },
  },

  {
    path: '/orders/income-preview/:id',
    name: 'income-preview',

    component: () => import('@/views/orders/orders-preview/SaleOrderPreview.vue'),
    meta: {
      Permission: 'order_show',
    },
  },

  {
    path: '/orders/income-edit/:id',
    name: 'income-edit',
    component: () => import('@/views/orders/orders-add/EditSaleOrder.vue'),
    meta: {
      Permission: 'order_edit',
    },
  },

  {
    path: '/orders/adjustment-edit/:id',
    name: 'adjustment-edit',
    component: () => import('@/views/orders/orders-add/EditAdjustment.vue'),
    meta: {
      Permission: 'order_edit',
    },
  },

  {
    path: '/orders/adjustment-preview/:id',
    name: 'adjustment-preview',

    component: () => import('@/views/orders/orders-preview/AdjustmentPreview.vue'),
    meta: {
      Permission: 'order_show',
    },
  },


];
