export default [
  {
    path: '/lease/inception-add',
    name: 'lease-inception-add',
    component: () => import('@/views/lease/create-inception/AddQuote.vue'),

    meta: {
      Permission: 'lease_inception_create',
    },
  },
  {
    path: '/lease/return-add',
    name: 'lease-return-add',
    component: () => import('@/views/lease/create-return/AddQuote.vue'),

    meta: {
      Permission: 'lease_return_create',
    },
  },
  {
    path: '/lease/quote-list',
    name: 'lease-list',
    component: () => import('@/views/lease/quote-list/QuoteListShow.vue'),

    meta: {
      Permission: 'lease_quote_list',
    },
  },

  {
    path: '/lease/inventory-list',
    name: 'lease-inventory-list',
    component: () => import('@/views/lease/quote-list/LeaseInventoryList.vue'),

    meta: {
      Permission: 'lease_quote_list',
    },
  },

  {
    path: '/lease/archive-quote-list',
    name: 'archive-lease-list',
    component: () => import('@/views/lease/quote-list/QuoteListArchive.vue'),

    meta: {
      Permission: 'view_lease_archive_records',
    },
  },

  {
    path: '/lease/inception-edit/:id',
    name: 'lease-edit',
    component: () => import('@/views/lease/create-inception/EditQuote.vue'),
    meta: {
      Permission: 'lease_quote_edit',
    },
  },

  {
    path: '/lease/return-edit/:id',
    name: 'lease-edit',
    component: () => import('@/views/lease/create-return/EditQuote.vue'),
    meta: {
      Permission: 'lease_quote_edit',
    },
  },

  // {
  //   path: '/lease/lease-validation-edit/:id',
  //   name: 'lease-validation-edit',
  //   component: () => import('@/views/lease/create-quote/EditQuote.vue'),
  //   meta: {
  //     Permission: 'no_permission_required',
  //   },
  // },

  {
    path: '/lease/quote-preview/:id',
    name: 'lease-preview',
    component: () => import('@/views/lease/quote-preview/PreviewQuote.vue'),

    meta: {
      Permission: 'lease_quote_show',
    },
  },

  {
    path: '/lease/archive-quote-preview/:id',
    name: 'archive-lease-preview',
    component: () => import('@/views/lease/quote-preview/PreviewQuote.vue'),

    meta: {
      Permission: 'lease_quote_show',
    },
  },
];
