import Vue from 'vue';
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';
// import Echo from 'laravel-echo';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
/*import '@/libs/acl';*/
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';

// import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';

// Axios Mock Adapter
import '@/@fake-db/db';
//Permissions mixin
import Permissions from './mixins/Permissions';
Vue.mixin(Permissions);
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
// Composition API
Vue.use(VueCompositionAPI);

const socket = io('https://regenwald-socket.azurewebsites.net');
// const socket = io('localhost:3000');
// const socket = 'close';

require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;
Vue.prototype.$socket = socket;
Vue.prototype.$chatUserList = [];

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
