export default [
  {
    path: '/customer/customer-list',
    name: 'customer-list',

    component: () => import('@/views/customer/customer-list/CustomerListShow.vue'),
    meta: {
      Permission: 'customer_list',
    },
  },

  {
    path: '/customer/archive-customer-list',
    name: 'archive-customer-list',

    component: () => import('@/views/customer/customer-list/CustomerListArchive.vue'),
    meta: {
      Permission: 'view_customer_archive_records',
    },
  },

  {
    path: '/customer/customer-edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/customer/customer-add/CustomerEdit.vue'),
    meta: {
      Permission: 'customer_edit',
    },
  },

  {
    path: '/customer/customer-add',
    name: 'customer-add',
    component: () => import('@/views/customer/customer-add/CustomerAdd.vue'),
    meta: {
      Permission: 'customer_create',
    },
  },

  {
    path: '/customer/customer-add-purchase',
    name: 'customer-add-purchase',
    component: () => import('@/views/customer/customer-add/CustomerAdd.vue'),
    meta: {
      Permission: 'customer_create',
    },
  },

  {
    path: '/customer/purchase-customer-edit/:id',
    name: 'purchase-customer-edit',
    component: () => import('@/views/customer/customer-add/CustomerEdit.vue'),
    meta: {
      Permission: 'customer_edit',
    },
  },

  {
    path: '/customer/customer-edit-purchase/:id/:quoteid',
    name: 'customer-edit-purchase',
    component: () => import('@/views/customer/customer-add/CustomerEdit.vue'),
    meta: {
      Permission: 'purchase_quote_create',
    },
  },
  {
    path: '/customer/customer-edit-sale/:id/:quoteid',
    name: 'customer-edit-sale',
    component: () => import('@/views/customer/customer-add/CustomerEdit.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },

  {
    path: '/customer/customer-edit-inception/:id/:quoteid',
    name: 'customer-edit-inception',
    component: () => import('@/views/customer/customer-add/CustomerEdit.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },
];
