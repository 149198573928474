export default [
  {
    path: '/purchase/quote-add',
    name: 'quote-add',
    component: () => import('@/views/purchase/create-quote/AddQuote.vue'),

    meta: {
      Permission: 'purchase_quote_create',
    },
  },
  {
    path: '/purchase/quote-list',
    name: 'quote-list',
    component: () => import('@/views/purchase/quote-list/QuoteListShow.vue'),

    meta: {
      Permission: 'purchase_quote_list',
    },
  },

  {
    path: '/purchase/archive-quote-list',
    name: 'archive-quote-list',
    component: () => import('@/views/purchase/quote-list/QuoteListArchive.vue'),

    meta: {
      Permission: 'view_purchase_archive_records',
    },
  },

  {
    path: '/purchase/quote-edit/:id',
    name: 'quote-edit',
    component: () => import('@/views/purchase/create-quote/EditQuote.vue'),
    meta: {
      Permission: 'purchase_quote_edit',
    },
  },

  {
    path: '/purchase/quote-validation-edit/:id',
    name: 'quote-validation-edit',
    component: () => import('@/views/purchase/create-quote/EditQuote.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/purchase/quote-preview/:id',
    name: 'quote-preview',
    component: () => import('@/views/purchase/quote-preview/PreviewQuote.vue'),

    meta: {
      Permission: 'purchase_quote_show',
      // layout: 'full',
    },
  },

  {
    path: '/purchase/archive-quote-preview/:id',
    name: 'archive-quote-preview',
    component: () => import('@/views/purchase/quote-preview/PreviewQuote.vue'),

    meta: {
      Permission: 'purchase_quote_show',
      // layout: 'full',
    },
  },

  //purchase
];
