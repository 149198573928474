export default [
  {
    path: '/inventory/inventory-list',
    name: 'inventory-list',
    component: () => import('@/views/inventory/inventory-list/InventoryListShow.vue'),
    meta: {
      Permission: 'inventory_list',
    },
  },

  {
    path: '/inventory/archive-inventory-list',
    name: 'archive-inventory-list',
    component: () => import('@/views/inventory/inventory-list/InventoryListArchive.vue'),
    meta: {
      Permission: 'view_inventory_archive_records',
    },
  },

  {
    path: '/inventory/inventory-list2',
    name: 'inventory-list2',
    component: () => import('@/views/inventory/inventory-list/InventoryList2.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      Permission: 'inventory_list',
    },
  },

  {
    path: '/inventory/web-list',
    name: 'web-list',
    component: () => import('@/views/inventory/web-list/EditWebList'),
    meta: {
      Permission: 'inventory_list',
    },
  },

  {
    path: '/inventory/inventory-edit/:id',
    name: 'inventory-edit',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'inventory_edit',
    },
  },

  {
    path: '/inventory/inventory-details/:id',
    name: 'inventory-details',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'vehicle_details_edit',
    },
  },
  {
    path: '/inventory/vehicle-decleration/:id',
    name: 'vehicle-decleration',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'vehicle_decleration_edit',
    },
  },

  {
    path: '/inventory/vehicle-purchase-decleration/:id/:quoteid',
    name: 'vehicle-purchase-decleration',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'purchase_quote_create',
    },
  },

  {
    path: '/inventory/vehicle-sale-decleration/:id/:quoteid',
    name: 'vehicle-sale-decleration',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },

  {
    path: '/inventory/vehicle-inception-decleration/:id/:quoteid',
    name: 'vehicle-inception-decleration',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },

  {
    path: '/inventory/vehicle-listing/:id',
    name: 'vehicle-listing',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'vehicle_listing_edit',
    },
  },

  {
    path: '/inventory/inventory-edit-sale/:id/:quoteid',
    name: 'inventory-edit-sale',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },

  {
    path: '/inventory/inventory-edit-inception/:id/:quoteid',
    name: 'inventory-edit-inception',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },

  {
    path: '/inventory/inventory-edit-purchase/:id/:purchaseid',
    name: 'inventory-edit-purchase',
    component: () => import('@/views/inventory/inventory-add/InventoryEdit.vue'),
    meta: {
      Permission: 'purchase_quote_create',
    },
  },

  {
    path: '/inventory/inventory-view/:id',
    name: 'inventory-view',
    component: () => import('@/views/inventory/inventory-view/InventoryView.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      Permission: 'inventory_show',
    },
  },

  // {
  //   path: '/inventory/inventory-view/:id',
  //   name: 'inventory-view',
  //   component: () => import('@/views/inventory/inventory-view/InventoryView.vue'),
  //   meta: {
  //     contentClass: 'ecommerce-application',
  //     Permission: 'inventory_show',
  //   },
  // },

  {
    path: '/inventory/inventory-publishedit/:id',
    name: 'inventory-publishedit',
    component: () => import('@/views/inventory/inventory-view/InventoryViewPublish.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      Permission: 'publish',
    },
  },

  {
    path: '/inventory/inventory-add',
    name: 'inventory-add',
    component: () => import('@/views/inventory/inventory-add/InventoryAdd.vue'),
    meta: {
      Permission: 'inventory_create',
    },
  },

  {
    path: '/inventory/inventory-add-sale',
    name: 'inventory-add-sale',
    component: () => import('@/views/inventory/inventory-add/InventoryAdd.vue'),
    meta: {
      Permission: 'sale_quote_create',
    },
  },

  {
    path: '/inventory/inventory-add-purchase',
    name: 'inventory-add-purchase',
    component: () => import('@/views/inventory/inventory-add/InventoryAdd.vue'),
    meta: {
      Permission: 'inventory_create',
    },
  },

  {
    path: '/inventory/inventory-add-inception',
    name: 'inventory-add-inception',
    component: () => import('@/views/inventory/inventory-add/InventoryAdd.vue'),
    meta: {
      Permission: 'inventory_create',
    },
  },

  {
    path: '/inventory/inventory-add-return',
    name: 'inventory-add-return',
    component: () => import('@/views/inventory/inventory-add/InventoryAdd.vue'),
    meta: {
      Permission: 'inventory_create',
    },
  },
];
