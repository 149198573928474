export default [
  {
    path: "/testlease/inception/quote-add",
    name: "testlease-add",
    component: () =>
      import("@/views/testlease/inception/create-quote/AddQuote.vue"),

    meta: {
      Permission: "inception_quote_create",
    },
  },
  {
    path: "/testlease/inception/quote-list",
    name: "testlease-list",
    component: () =>
      import("@/views/testlease/inception/quote-list/QuoteListShow.vue"),

    meta: {
      Permission: "inception_quote_list",
    },
  },

  {
    path: "/testlease/inception/archive-quote-list",
    name: "archive-testlease-list",
    component: () =>
      import("@/views/testlease/inception/quote-list/QuoteListArchive.vue"),

    meta: {
      Permission: "view_inception_archive_records",
    },
  },

  {
    path: "/testlease/inception/quote-edit/:id",
    name: "testlease-edit",
    component: () =>
      import("@/views/testlease/inception/create-quote/EditQuote.vue"),
    meta: {
      Permission: "inception_quote_edit",
    },
  },

  {
    path: "/testlease/inception/testlease-validation-edit/:id",
    name: "testlease-validation-edit",
    component: () =>
      import("@/views/testlease/inception/create-quote/EditQuote.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/testlease/inception/quote-preview/:id",
    name: "testlease-preview",
    component: () =>
      import("@/views/testlease/inception/quote-preview/PreviewQuote.vue"),

    meta: {
      Permission: "inception_quote_show",
    },
  },

  {
    path: "/testlease/inception/archive-quote-preview/:id",
    name: "archive-testlease-preview",
    component: () =>
      import("@/views/testlease/inception/quote-preview/PreviewQuote.vue"),

    meta: {
      Permission: "inception_quote_show",
    },
  },

  {
    path: "/testlease/offroad/quote-add",
    name: "offroad-add",
    component: () =>
      import("@/views/testlease/return/create-quote/AddQuote.vue"),

    meta: {
      Permission: "offroad_quote_create",
    },
  },
  {
    path: "/testlease/offroad/quote-list",
    name: "offroad-list",
    component: () =>
      import("@/views/testlease/return/quote-list/QuoteListShow.vue"),

    meta: {
      Permission: "offroad_quote_list",
    },
  },

  {
    path: "/testlease/offroad/archive-quote-list",
    name: "archive-offroad-list",
    component: () =>
      import("@/views/testlease/return/quote-list/QuoteListArchive.vue"),

    meta: {
      Permission: "view_offroad_archive_records",
    },
  },

  {
    path: "/testlease/offroad/quote-edit/:id",
    name: "offroad-edit",
    component: () =>
      import("@/views/testlease/return/create-quote/EditQuote.vue"),
    meta: {
      Permission: "offroad_quote_edit",
    },
  },

  {
    path: "/testlease/offroad/quote-validation-edit/:id",
    name: "offroad-validation-edit",
    component: () =>
      import("@/views/testlease/return/create-quote/EditQuote.vue"),
    meta: {
      Permission: "no_permission_required",
    },
  },

  {
    path: "/testlease/offroad/quote-preview/:id",
    name: "offroad-preview",
    component: () =>
      import("@/views/testlease/return/quote-preview/PreviewQuote.vue"),

    meta: {
      Permission: "offroad_quote_show",
      // layout: 'full',
    },
  },

  {
    path: "/testlease/offroad/archive-quote-preview/:id",
    name: "archive-offroad-preview",
    component: () =>
      import("@/views/testlease/return/quote-preview/PreviewQuote.vue"),

    meta: {
      Permission: "offroad_quote_show",
      // layout: 'full',
    },
  },

  {
    path: "/testlease/payments/payment-list",
    name: "payment-list",
    component: () => import("@/views/testlease/payments/PaymentList.vue"),

    meta: {
      Permission: "inception_quote_list",
      // layout: 'full',
    },
  },

  {
    path: "/testlease/payments/payment-preview/:id",
    name: "payment-preview",
    component: () => import("@/views/testlease/payments/PaymentPreview.vue"),

    meta: {
      Permission: "inception_quote_list",
      // layout: 'full',
    },
  },

  {
    path: "/testlease/inventory",
    name: "testlease-inventory",
    component: () => import("@/views/testlease/LeaseInventoryList.vue"),

    meta: {
      Permission: "inception_quote_list",
      // layout: 'full',
    },
  },

  {
    path: "/testlease/inventory-view/:id",
    name: "testlease-inventory-view",
    component: () => import("@/views/testlease/LeaseInventoryView.vue"),

    meta: {
      Permission: "inception_quote_list",
      // layout: 'full',
    },
  },
];
